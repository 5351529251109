<template>
  <div v-if="isAdmin || checkAccess('client-summary')">
    <report-list-page
        page-title="Client Summary Report"
        page-icon="mdi-chart-timeline"
        :descending="true"
        :headers="headers"
        state-end-point="clientSummary.clientSummaries"
        :actions="actions"
        :show-component="showComponent"
        sort="email"
    />
  </div>
</template>

<script>
import ReportListPage from "../../components/ReportListPage";
import IsAuthenticated from "../../middleware/IsAuthenticated";
export default {
  name: "ClientSummaryReport",
  mixins: [IsAuthenticated],
  components: {ReportListPage},
  data() {
    return {
      headers: [
        {
          text: 'Email',
          value: 'email'
        },
        {
          text: 'Total Paid',
          value: 'totalPaidAmount',
          align: 'right'
        },
        {
          text: 'Total Unpaid',
          value: 'totalDue',
          align: 'right'
        },
        {
          text: 'Total Deleted',
          value: 'totalDeleted',
          align: 'right'
        },
        {
          text: 'Total Live Link',
          value: 'totalLiveLink',
          align: 'right'
        },
        {
          text: 'Last Date of Order',
          value: 'numsOfDate'
        }
      ],
      actions: {
        load: 'clientSummary/load',
      },
      showComponent: {
        isUser: false,
        isStatus: false,
        isMethod: false,
        isEmail: true,
        isSite: false,
        isSummary: false,
        isClientType: true,
        isDate: true
      }
    }
  }
}
</script>

<style scoped>

</style>